import { Navigate, RouteObject } from "react-router-dom";

import { NavigateWithParams } from "components/routing";
import { EModuleCode } from "features/chapter-finder/chapter-finder.types";
import { evaIntentHandlerLoader } from "routes/eva-intent-handler/eva-intent-handler";
import lazyWithRetry from "routes/lazy-with-retry";
import ModuleChapterRedirecter from "routes/module-chapter-redirecter";
import promotions from "routes/route-definitions/promotions";

// Parent is root /
export const promotionsRoutes: RouteObject[] = [
  {
    path: promotions.module.path,
    lazy: lazyWithRetry(() => import("./promotions")),
    children: [
      { index: true, element: <ModuleChapterRedirecter moduleCode={EModuleCode.PROMOTIONS} /> },
      {
        path: promotions.promotions.overview.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./promotions.promotions._index"),
            import("./promotions.promotions.search-coupons"),
          ]);
          return page;
        }),
        children: [
          {
            path: promotions.promotions.overview.searchCoupons.path,
            lazy: lazyWithRetry(() => import("./promotions.promotions.search-coupons")),
          },
        ],
      },
      {
        path: promotions.promotions.details.path,
        lazy: lazyWithRetry(() => import("./promotions.promotions.$id")),
      },
      {
        path: "home",
        children: [
          {
            index: true,
            element: <Navigate to={promotions.promotions.overview.path} />,
          },
          {
            path: ":id",
            element: <NavigateWithParams path={promotions.promotions.details.path} />,
          },
        ],
      },
      {
        path: promotions.personalizedPromotions.overview.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./promotions.personalized-promotions"),
            import("./promotions.personalized-promotions.create"),
            import("./promotions.personalized-promotions.$id.edit"),
            import("./promotions.personalized-promotions.$id.delete"),
          ]);
          return page;
        }),
        children: [
          {
            path: promotions.personalizedPromotions.overview.create.path,
            lazy: lazyWithRetry(() => import("./promotions.personalized-promotions.create")),
          },

          {
            path: promotions.personalizedPromotions.overview.edit.path,
            lazy: lazyWithRetry(() => import("./promotions.personalized-promotions.$id.edit")),
          },

          {
            path: promotions.personalizedPromotions.overview.delete.path,
            lazy: lazyWithRetry(() => import("./promotions.personalized-promotions.$id.delete")),
          },
        ],
      },
      {
        path: promotions.campaigns.wrapper.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./promotions.campaigns"),
            import("./promotions.campaigns._index"),
            import("./promotions.campaigns.new"),
            import("./promotions.campaigns.$id"),
            import("./promotions.campaigns.$id.edit-budget"),
            import("./promotions.campaigns.$id.edit-information"),
            import("./promotions.campaigns.$id.delete"),
          ]);
          return page;
        }),
        children: [
          {
            index: true,
            lazy: lazyWithRetry(() => import("./promotions.campaigns._index")),
          },
          {
            path: promotions.campaigns.create.path,
            lazy: lazyWithRetry(() => import("./promotions.campaigns.new")),
          },
          {
            path: promotions.campaigns.details.path,
            lazy: lazyWithRetry(() => import("./promotions.campaigns.$id")),

            children: [
              {
                path: promotions.campaigns.details.editInformation.path,
                lazy: lazyWithRetry(() => import("./promotions.campaigns.$id.edit-information")),
              },
              {
                path: promotions.campaigns.details.editBudget.path,
                lazy: lazyWithRetry(() => import("./promotions.campaigns.$id.edit-budget")),
              },
              {
                path: promotions.campaigns.details.delete.path,
                lazy: lazyWithRetry(() => import("./promotions.campaigns.$id.delete")),
              },
            ],
          },
        ],
      },

      {
        path: promotions.templates.overview.path,
        children: [
          {
            index: true,
            lazy: lazyWithRetry(() => import("./promotions.discount-templates._index")),
          },
          {
            path: promotions.templates.details.path,
            lazy: lazyWithRetry(() => import("./promotions.discount-templates.$id")),
          },
        ],
      },

      {
        path: promotions.loyaltyPrograms.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./promotions.loyalty-programs._index"),
            import("./promotions.loyalty-programs.loyalty-programs-overview"),
            import("./promotions.loyalty-programs.loyalty-program-groups"),
          ]);

          return page;
        }),
        children: [
          {
            path: promotions.loyaltyPrograms.loyaltyProgramsOverview.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./promotions.loyalty-programs.loyalty-programs-overview"),
                import("./promotions.loyalty-programs.loyalty-programs-overview.new"),
                import("./promotions.loyalty-programs.loyalty-programs-overview.$id"),
              ]);

              return page;
            }),
          },
          {
            path: promotions.loyaltyPrograms.loyaltyProgramGroups.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./promotions.loyalty-programs.loyalty-program-groups"),
                import("./promotions.loyalty-programs.loyalty-program-groups.new"),
                import("./promotions.loyalty-programs.loyalty-program-groups.$id.edit"),
                import("./promotions.loyalty-programs.loyalty-program-groups.$id.delete"),
              ]);

              return page;
            }),
            children: [
              {
                path: promotions.loyaltyPrograms.loyaltyProgramGroups.create.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.loyalty-programs.loyalty-program-groups.new"),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramGroups.edit.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.loyalty-programs.loyalty-program-groups.$id.edit"),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramGroups.delete.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.loyalty-programs.loyalty-program-groups.$id.delete"),
                ),
              },
            ],
          },
        ],
      },

      {
        path: promotions.loyaltyPrograms.loyaltyProgramsOverview.create.path,
        lazy: lazyWithRetry(
          () => import("./promotions.loyalty-programs.loyalty-programs-overview.new"),
        ),
      },

      {
        path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./promotions.loyalty-programs.loyalty-programs-overview.$id"),
            import(
              "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information"
            ),
            import(
              "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules"
            ),
            import("./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types"),
            import("./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions"),
            import(
              "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations"
            ),
          ]);

          return page;
        }),
        children: [
          {
            path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.generalInformation
              .path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.edit-general-information"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.custom-fields.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.custom-fields.$customFieldID.delete"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.price-lists.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.price-lists.$priceListID.delete"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.ou-sets.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.ou-sets.$ouSetID.delete"
                ),
              ]);

              return page;
            }),
            children: [
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.generalInformation
                  .editGeneralInformation.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.edit-general-information"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.generalInformation
                  .customFields.add.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.custom-fields.new"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.generalInformation
                  .customFields.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.custom-fields.$customFieldID.delete"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.generalInformation
                  .priceLists.add.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.price-lists.new"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.generalInformation
                  .priceLists.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.price-lists.$priceListID.delete"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.generalInformation
                  .ouSets.add.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.ou-sets.new"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.generalInformation
                  .ouSets.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.ou-sets.$ouSetID.edit"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.generalInformation
                  .ouSets.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.ou-sets.$ouSetID.delete"
                    ),
                ),
              },
            ],
          },

          {
            path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.pointSavingRules.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.edit"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.delete"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.order-amount.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.order-amount.$conditionID.edit"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.product-set.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.product-set.$conditionID.edit"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.consumer.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.consumer.$conditionID.edit"
                ),
              ]);

              return page;
            }),
            children: [
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.pointSavingRules
                  .create.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.new"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.pointSavingRules
                  .edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.edit"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.pointSavingRules
                  .delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.delete"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.pointSavingRules
                  .conditions.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.delete.$conditionID"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.pointSavingRules
                  .conditions.orderAmount.add.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.order-amount.new"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.pointSavingRules
                  .conditions.orderAmount.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.order-amount.$conditionID.edit"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.pointSavingRules
                  .conditions.productSet.add.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.product-set.new"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.pointSavingRules
                  .conditions.productSet.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.product-set.$conditionID.edit"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.pointSavingRules
                  .conditions.consumer.add.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.consumer.new"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.pointSavingRules
                  .conditions.consumer.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.consumer.$conditionID.edit"
                    ),
                ),
              },
            ],
          },

          {
            path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.paymentTypes.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types"),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.delete"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.edit"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.product-search-template"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.product-ids"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.product-ids.$productID.delete"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.product-filters.new-bulk"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.product-filters.($propertyName)"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.product-filters.$propertyName.delete"
                ),
              ]);

              return page;
            }),
            children: [
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.paymentTypes.delete
                  .path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.delete"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.paymentTypes.create
                  .path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.new"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.paymentTypes.edit
                  .path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.edit"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.paymentTypes
                  .productLimitationData.productSearchTemplate.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.product-search-template"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.paymentTypes
                  .productLimitationData.productIDs.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.product-ids"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.paymentTypes
                  .productLimitationData.productIDs.deleteProduct.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.product-ids.$productID.delete"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.paymentTypes
                  .productLimitationData.productFilters.createBulk.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.product-filters.new-bulk"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.paymentTypes
                  .productLimitationData.productFilters.createOrEdit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.product-filters.($propertyName)"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.paymentTypes
                  .productLimitationData.productFilters.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.product-filters.$propertyName.delete"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.paymentTypes
                  .productLimitationData.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.delete-products"
                    ),
                ),
              },
            ],
          },

          {
            path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions"),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.customer.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.delete.$conditionID"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.customer.$conditionID.edit-general-information"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.customer.$conditionID.$filter"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.customer.$conditionID.$filter.delete"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.user-custom-fields.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.user-custom-fields.$conditionID.edit-general-information"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.user-custom-fields.$conditionID.custom-fields.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.user-custom-fields.$conditionID.custom-fields.$customFieldID.$index.delete"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.user-custom-fields.$conditionID.custom-fields.$customFieldID.$index.edit"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-custom-fields.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-custom-fields.$conditionID.edit-general-information"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-custom-fields.$conditionID.custom-fields.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-custom-fields.$conditionID.custom-fields.$customFieldID.$index.delete"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-custom-fields.$conditionID.custom-fields.$customFieldID.$index.edit"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-type.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-type.$conditionID.edit-general-information"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-properties.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-properties.$conditionID.edit-general-information"
                ),
              ]);

              return page;
            }),
            children: [
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions.delete
                  .path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.delete.$conditionID"
                    ),
                ),
              },

              /** Customer */
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions.customer
                  .create.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.customer.new"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions.customer
                  .generalInformation.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.customer.$conditionID.edit-general-information"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions.customer
                  .filters.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.customer.$conditionID.$filter"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions.customer
                  .filters.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.customer.$conditionID.$filter.delete"
                    ),
                ),
              },

              /** User custom fields */
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .userCustomFields.create.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.user-custom-fields.new"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .userCustomFields.generalInformation.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.user-custom-fields.$conditionID.edit-general-information"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .userCustomFields.customFields.create.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.user-custom-fields.$conditionID.custom-fields.new"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .userCustomFields.customFields.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.user-custom-fields.$conditionID.custom-fields.$customFieldID.$index.delete"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .userCustomFields.customFields.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.user-custom-fields.$conditionID.custom-fields.$customFieldID.$index.edit"
                    ),
                ),
              },

              /** Order custom fields */
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .orderCustomFields.create.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-custom-fields.new"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .orderCustomFields.generalInformation.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-custom-fields.$conditionID.edit-general-information"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .orderCustomFields.customFields.create.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-custom-fields.$conditionID.custom-fields.new"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .orderCustomFields.customFields.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-custom-fields.$conditionID.custom-fields.$customFieldID.$index.delete"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .orderCustomFields.customFields.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-custom-fields.$conditionID.custom-fields.$customFieldID.$index.edit"
                    ),
                ),
              },

              /** Order type */
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .orderType.create.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-type.new"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .orderType.generalInformation.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-type.$conditionID.edit-general-information"
                    ),
                ),
              },

              /** Order properties */
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .orderProperties.create.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-properties.new"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .orderProperties.generalInformation.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-properties.$conditionID.edit-general-information"
                    ),
                ),
              },
            ],
          },

          {
            path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.productLimitations
              .path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.delete"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.delete-products"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.edit"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.product-search-template"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.product-ids"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.product-ids.$productID.delete"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.product-filters.new-bulk"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.product-filters.($propertyName)"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.product-filters.$propertyName.delete"
                ),
              ]);

              return page;
            }),
            children: [
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.productLimitations
                  .create.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.new"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.productLimitations
                  .delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.delete"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.productLimitations
                  .edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.edit"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.productLimitations
                  .productLimitationData.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.delete-products"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.productLimitations
                  .productLimitationData.productSearchTemplate.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.product-search-template"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.productLimitations
                  .productLimitationData.productIDs.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.product-ids"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.productLimitations
                  .productLimitationData.productIDs.deleteProduct.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.product-ids.$productID.delete"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.productLimitations
                  .productLimitationData.productFilters.createBulk.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.product-filters.new-bulk"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.productLimitations
                  .productLimitationData.productFilters.createOrEdit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.product-filters.($propertyName)"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.productLimitations
                  .productLimitationData.productFilters.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.product-filters.$propertyName.delete"
                    ),
                ),
              },
            ],
          },
        ],
      },

      {
        path: "link",
        loader: evaIntentHandlerLoader,
      },

      {
        path: ":id",
        element: <NavigateWithParams path={promotions.promotions.details.path} />,
      },
    ],
  },
];
