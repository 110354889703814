import { generatePath, Navigate, RouteObject, useParams } from "react-router-dom";

import { ErrorFallback } from "components/shared/error-fallback";
import { evaIntentHandlerLoader } from "routes/eva-intent-handler/eva-intent-handler";
import routeDefinitions from "routes/route-definitions";

const { controlRoom } = routeDefinitions;

const SettingsDetailsRedirect = () => {
  const params = useParams();

  if (params.ouid && params.setting) {
    // Redirect to settings details
    return (
      <Navigate
        to={generatePath(controlRoom.settings.details.path, {
          ouid: params.ouid,
          setting: params.setting,
        })}
        replace
      />
    );
  } else if (params.ouid && params.key && params.app) {
    return (
      // Redirect to app settings details
      <Navigate
        to={generatePath(controlRoom.appSettings.details.path, {
          ouid: params.ouid,
          key: params.key,
          app: params.app,
        })}
        replace
      />
    );
  }

  return <Navigate to={routeDefinitions.auth.notFound.path} />;
};

export const settingsRoutes: RouteObject = {
  path: "settings",
  errorElement: <ErrorFallback fullPage />,
  children: [
    {
      index: true,
      element: <Navigate to={controlRoom.settings.overview.path} replace />,
    },
    {
      path: ":ouid/:setting",
      element: <SettingsDetailsRedirect />,
    },
    {
      path: "app-settings",
      element: <Navigate to={controlRoom.appSettings.overview.path} replace />,
    },
    {
      path: "app-settings/:ouid/:key/:app",
      element: <SettingsDetailsRedirect />,
    },
    {
      path: "link",
      loader: evaIntentHandlerLoader,
    },
  ],
};
