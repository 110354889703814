import { generatePath, LoaderFunctionArgs, redirect } from "react-router-dom";

import { listAvailableUserTasksLoaderQuery } from "models/user-tasks";
import routeDefinitions from "routes/route-definitions";
import { hasTasksOfSubtype } from "routes/tasks/tasks.ship-from-store/ship-from-store-overview.helpers";
import {
  DEFAULT_SHIP_FROM_STORE_TASKS_REQUEST,
  SfsTaskSubtype,
  SHIP_FROM_STORE_TASKS_QUERY_KEY,
} from "routes/tasks/tasks.ship-from-store/ship-from-store-overview.types";
import { queryClient } from "util/query-client";
import { routeParamsIsPositiveInteger } from "util/validators/route-param-is-positive-integer";

export const taskDetailsSimpleRedirecterLoader = async ({ params }: LoaderFunctionArgs) => {
  const taskId = routeParamsIsPositiveInteger(params, "id");

  const availableShipFromStoreTasks = await listAvailableUserTasksLoaderQuery(
    queryClient,
    DEFAULT_SHIP_FROM_STORE_TASKS_REQUEST,
    [SHIP_FROM_STORE_TASKS_QUERY_KEY],
  )();

  const task = availableShipFromStoreTasks.value?.AvailableTasks?.find(
    (task) => task.ID === taskId,
  );

  if (task) {
    switch (task.SubType.Name) {
      case SfsTaskSubtype.Pick:
        return redirect(
          generatePath(routeDefinitions.tasks.shipFromStore.pick.details.simpleTask.path, {
            id: taskId.toString(),
          }),
        );
      case SfsTaskSubtype.Ship:
        return redirect(
          generatePath(routeDefinitions.tasks.shipFromStore.ship.details.simpleTask.path, {
            id: taskId.toString(),
          }),
        );
      case SfsTaskSubtype.Print:
        return redirect(
          generatePath(routeDefinitions.tasks.shipFromStore.print.details.simpleTask.path, {
            id: taskId.toString(),
          }),
        );
      default:
        throw new Response("Not found", { status: 404 });
    }
  }

  throw new Response("Not found", { status: 404 });
};

export const taskDetailsCompositeRedirecterLoader = async ({ params }: LoaderFunctionArgs) => {
  const taskId = routeParamsIsPositiveInteger(params, "id");
  const compositeTaskId = routeParamsIsPositiveInteger(params, "compositeId");

  const availableShipFromStoreTasks = await listAvailableUserTasksLoaderQuery(
    queryClient,
    DEFAULT_SHIP_FROM_STORE_TASKS_REQUEST,
    [SHIP_FROM_STORE_TASKS_QUERY_KEY],
  )();

  const compositeTask = availableShipFromStoreTasks.value?.AvailableTasks?.find(
    (task) => task.ID === compositeTaskId,
  );
  const task = compositeTask?.Children?.find((childTask) => childTask.ID === taskId);

  if (task && compositeTask) {
    switch (task.SubType.Name) {
      case SfsTaskSubtype.Pick:
        return redirect(
          generatePath(routeDefinitions.tasks.shipFromStore.pick.details.compositeTask.path, {
            id: taskId.toString(),
            compositeId: compositeTaskId.toString(),
          }),
        );
      case SfsTaskSubtype.Ship:
        // make sure there is no pick task to be completed first
        if (hasTasksOfSubtype(compositeTask.Children, SfsTaskSubtype.Pick)) {
          throw new Response("Not found", { status: 404 });
        }
        return redirect(
          generatePath(routeDefinitions.tasks.shipFromStore.ship.details.compositeTask.path, {
            id: taskId.toString(),
            compositeId: compositeTaskId.toString(),
          }),
        );
      case SfsTaskSubtype.Print:
        return redirect(
          generatePath(routeDefinitions.tasks.shipFromStore.print.details.compositeTask.path, {
            id: taskId.toString(),
            compositeId: compositeTaskId.toString(),
          }),
        );
      default:
        throw new Response("Not found", { status: 404 });
    }
  }

  throw new Response("Not found", { status: 404 });
};
