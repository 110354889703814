import { IAvailableUserTaskDto } from "components/shared/available-user-tasks/table/types";

import { SfsTaskSubtype } from "./ship-from-store-overview.types";

/** checks if a composite task has a child ship task, but not a child pick or pack task (as we need to complete pick and pack tasks first) */
export const hasAvailableShipChildTask = (task: EVA.Core.AvailableUserTaskDto) =>
  task?.Children?.some((childTask) => childTask.SubType?.Name === SfsTaskSubtype.Ship) &&
  task?.Children?.every(
    (childTask) =>
      childTask.SubType?.Name !== SfsTaskSubtype.Pick &&
      childTask.SubType?.Name !== SfsTaskSubtype.Pack,
  );

/** checks if a composite task has a child pack task, but not a child pick task (as we need to complete pick tasks first) */
export const hasAvailablePackChildTask = (task: EVA.Core.AvailableUserTaskDto) =>
  task?.Children?.some((childTask) => childTask.SubType?.Name === SfsTaskSubtype.Pack) &&
  task?.Children?.every((childTask) => childTask.SubType?.Name !== SfsTaskSubtype.Pick);

export const getTasksBySubtype = (
  availableTasks: EVA.Core.AvailableUserTaskDto[] | undefined,
  subtype: SfsTaskSubtype,
) => {
  const tasksOfSubtype: IAvailableUserTaskDto[] =
    availableTasks?.filter((task) => {
      return task.SubType?.Name === subtype;
    }) ?? [];

  const childTasksOfSubtype: IAvailableUserTaskDto[] =
    availableTasks
      ?.filter((task) => {
        if (subtype === SfsTaskSubtype.Ship) {
          // when searching for ship tasks, ignore composite tasks that also have a pick and/or pack task available
          return hasAvailableShipChildTask(task);
        } else if (subtype === SfsTaskSubtype.Pack) {
          // when searching for pack tasks, ignore composite tasks that also have a pick task available
          return hasAvailablePackChildTask(task);
        } else {
          return task?.Children?.some((childTask) => childTask.SubType?.Name === subtype);
        }
      })
      ?.flatMap(
        (task) =>
          task?.Children?.filter((childTask) => childTask.SubType?.Name === subtype)?.map(
            (childTask) => ({ ...childTask, compositeUserTaskID: task.ID }),
          ) ?? [],
      ) ?? [];

  return [...tasksOfSubtype, ...childTasksOfSubtype];
};

export const hasTasksOfSubtype = (
  availableTasks: EVA.Core.AvailableUserTaskDto[] | undefined,
  subtype: SfsTaskSubtype,
) =>
  availableTasks?.some((task) => {
    // either the task itself is the correct subtype
    if (task.SubType?.Name === subtype) {
      return true;
    }

    // or it has a child of the correct subtype
    if (subtype === SfsTaskSubtype.Ship) {
      // when searching for ship tasks, ignore composite tasks that also have a pick task available
      return hasAvailableShipChildTask(task);
    }
    if (subtype === SfsTaskSubtype.Pack) {
      // when searching for pack tasks, ignore composite tasks that also have a pick task available
      return hasAvailablePackChildTask(task);
    } else {
      return task?.Children?.some((childTask) => childTask.SubType?.Name === subtype);
    }
  });
