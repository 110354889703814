import { z } from "zod";
import { zfd } from "zod-form-data";

import {
  EntityTranslationButtonTranslations,
  IEntityTranslationFieldIdentifier,
} from "../entity-translation-button/types";

const entityTranslationIdentifierSchema = z.object({
  EntityID: zfd.text(),
  EntityType: zfd.text(),
  EntityField: zfd.text(),
});

export const addEditFormSchema = entityTranslationIdentifierSchema.extend({
  CountryID: zfd.text(),
  LanguageID: zfd.text(),
  Value: zfd.text(),
  InitialCountryId: zfd.text().optional(),
  InitialLanguageId: zfd.text().optional(),
  Intent: zfd.text(z.union([z.literal("add"), z.literal("edit")])),
});

export const addEditFormKeys = addEditFormSchema.keyof().Values;

export const addEditFormErrorsSchema = z
  .record(addEditFormSchema.keyof(), z.string().optional())
  .optional();

export type AddEditFormErrors = z.infer<typeof addEditFormErrorsSchema>;

export const deleteFormSchema = entityTranslationIdentifierSchema.extend({
  LanguageID: zfd.text(),
  CountryID: zfd.text(),
});

export const deleteFormKeys = deleteFormSchema.keyof().Values;

export const deleteFormErrorsSchema = z
  .record(deleteFormSchema.keyof(), z.string().optional())
  .optional();

export type DeleteFormErrors = z.infer<typeof deleteFormErrorsSchema>;

export type SubModal =
  | { intent: "create" }
  | { intent: "edit" | "delete"; translation: EntityTranslation };

export type EntityTranslationActionHandler = Record<"onCreate", () => void> &
  Record<"onEdit" | "onDelete", (translation: EntityTranslation) => void>;

export interface EntityTranslationCreateEditModel extends IEntityTranslationFieldIdentifier {
  LanguageID: string;
  CountryID?: string;
  Value?: string;
}

export interface EntityTranslation extends IEntityTranslationFieldIdentifier {
  LanguageID: string;
  CountryID: string;
  Value: string;
}

export interface EntityTranslationWithNames extends EntityTranslation {
  CountryName?: string;
  LanguageName?: string;
}

export interface EntityTranslationMutationOptions {
  refetch: () => void;
  disableNotifications?: boolean;
  translations?: EntityTranslationButtonTranslations;
}
