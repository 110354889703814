import { useEffect } from "react";
import { RouteObject, useParams } from "react-router-dom";

const LegacyTasksModuleRedirecter = () => {
  const params = useParams();
  const chapter = params.chapter;

  useEffect(() => {
    if (chapter) {
      window.location.replace(`/tasks/${chapter}`);
    } else {
      window.location.replace("/tasks");
    }
  }, [chapter]);

  return null;
};

export const legacyTasksModuleRedirecterRouteObject: RouteObject = {
  path: "module/tasks",
  element: <LegacyTasksModuleRedirecter />,
  children: [
    {
      path: ":chapter",
      element: <LegacyTasksModuleRedirecter />,
    },
  ],
};
