import routeDefinitions from "routes/route-definitions";
import { searchParamsContextGenerator } from "util/search-params-context-generator";

const { SearchParamsProvider, useSearchParamsContext } = searchParamsContextGenerator(
  routeDefinitions.pim.productSets.overview.path,
);

export {
  SearchParamsProvider as OverviewSearchParamsProvider,
  useSearchParamsContext as useOverviewSearchParamsContext,
};
