import { redirect, RouteObject } from "react-router-dom";

import { ErrorFallback } from "components/shared/error-fallback";
import routeDefinitions from "routes/route-definitions";

import * as Unauthorized from "./__auth.401";
import * as NotFound from "./__auth.404";
import * as ForgotPassword from "./__auth.forgot-password";
import * as ForgotPasswordReset from "./__auth.forgot-password.$token";
import * as Login from "./__auth.login";
import LogoutPage from "./__auth.logout";

let SelectEndpoint;

const disableCusomterManager = import.meta.env.VITE_EVA_DISABLE_CUSTOMER_MANAGER === "true";
if (disableCusomterManager) {
  SelectEndpoint = null;
} else {
  SelectEndpoint = await import("./__auth.select-endpoint");
}

const { auth } = routeDefinitions;

// Parent is root /
export const authRoutes: RouteObject[] = [
  {
    path: auth.login.path,
    ...Login,
  },
  {
    path: auth.logout.path,
    errorElement: <ErrorFallback fullPage />,
    element: <LogoutPage />,
  },
  {
    path: auth.selectEndpoint.path,
    ...SelectEndpoint,
  },
  {
    path: auth.forgotPassword.path,
    ...ForgotPassword,
  },
  {
    path: auth.forgotPassword.changePassword.path,
    ...ForgotPasswordReset,
  },
  {
    path: auth.notAuthorized.path,
    ...Unauthorized,
  },
  {
    path: auth.notFound.path,
    ...NotFound,
  },
  {
    path: "*",
    loader: () => redirect(auth.notFound.path),
  },
];

const finalAuthRoutes = !disableCusomterManager
  ? [
      {
        path: auth.selectEndpoint.path,
        ...SelectEndpoint,
      },
      ...authRoutes,
    ]
  : authRoutes;

export default finalAuthRoutes;
