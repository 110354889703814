import ReactDOM from "react-dom/client";

import App from "App";

import "./styles/tailwind.css";
import "./styles/suite-ui.css";
import "./styles/react-medium-image-zoom.css";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById("root")!).render(<App />);
