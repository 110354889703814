import { intlAccessor } from "util/intl-accessor";

import { generateLyraCountrySearchListField } from "../../../../components/suite-composite/country-selector";
import { generateLanguageLyraSearchListField } from "../../../../components/suite-composite/languages-autocomplete";

export const CountryISOCodeSearchListField = generateLyraCountrySearchListField({
  getLabel: (country) =>
    `${intlAccessor.formatDisplayName(country.ID, { type: "region" })} (${country.ID})`,
});

export const LanguageISOCodeSearchListField = generateLanguageLyraSearchListField({
  getLabel: (language) =>
    `${intlAccessor.formatDisplayName(language.ID, { type: "language" })} (${language.ID})`,
});
