import { RouteObject } from "react-router-dom";

import lazyWithRetry from "../../lazy-with-retry";
import routeDefinitions from "../../route-definitions";

const { resource } = routeDefinitions;

export * from "./entity-translation-button";

export const entityTranslationsResourceRoutes: RouteObject[] = [
  {
    path: resource.entityTranslations.addEdit.path,
    lazy: lazyWithRetry(async () => import("./resource.entity-translations.add-edit")),
  },
  {
    path: resource.entityTranslations.delete.path,
    lazy: lazyWithRetry(async () => import("./resource.entity-translations.delete")),
  },
];
